export enum EPageState {
  // 当前页面未定
  NONE = 'none',
  // 登录页
  LOGIN = 'login',
  // 除了登陆状态之外，页面也会处于没有登陆的状态，需要登陆
  NEED_LOGIN = 'need_login',
  // 找回密码页
  FIND_PWD = 'find_pwd',
  // 应用账号失败页面
  APP_ACCOUNT_AUTH_FAIL = 'app_account_auth_fail',
  // 应用 token 失败页面（1.6.0 迭代沿用 portal 的 token 失败页面)
  APP_TOKEN_AUTH_FAIL = 'app_token_auth_fail',
  // 页面已被删除
  PAGE_NOT_FOUND = 'page_not_found',
  // 页面鉴权失败
  PAGE_ACCOUNT_AUTH_FAIL = 'page_account_auth_fail',
  // 页面正常渲染
  NORMAL_PAGE = 'normal_page',
}

export const SOLUTION_PREVIEW_BASENAME_REG = /^(\/web\/s\/[0-9a-z]+\/preview\/\d+)/i;
export const BASENAME_REG = /^(\/[0-9a-z]+)?(\/page\/[0-9]+|login\.htm)(\?|$)/i;
export const LOGIN_PATH = '/login.htm';
export const LOGOUT_PATH = '/logout.htm';
export const FIND_PASSWORD_PATH = '/login/findpwd';
export const PREVIEW_BASENAME = '/preview';
export const PAGE_ID_REG = /^\/page\/([0-9]+)(\?|$)/i;

export const OLD_MASTER_DSL_VERSION = '1.2.0';
export const CURRENT_MASTER_DSL_VERSION = '1.2.1';

export const OA_DOMAIN_MAP = {
  daily: 'https://openaccount-login-daily.iot.aliyun.test',
  pre: 'https://openaccount-login-pre.aliyun.com',
  prod: 'https://openaccount-login.aliyun.com',
};
export const LOGO_URL = 'https://img.alicdn.com/tfs/TB1U.LSdYj1gK0jSZFOXXc7GpXa-112-32.png';
export const BACKGROUND_IMAGE_URL = 'https://gw.alicdn.com/tfs/TB1QzFPiBBh1e4jSZFhXXcC9VXa-828-414.png';
export const WEB_MECHANISM_COMPONENTS_TYPE_IDS = ['group', 'menu'];
export const EXTERNAL_LIBS = [
  'babel-polyfill@6',
  'babel-runtime@6',
  'react@16',
  'react-dom@16',
  'react-router@4',
  'history@4',
  'redux@3',
  'react-redux@5',
  'brace@0.11',
  'bizcharts@3',
  'moment@2',
  'react-ace@6',
  'alife-next@1',
  'ali-wind@2',
  'ali-wind@3',
  'alife-iot-bone-console-ui@0.2',
  // 一些旧的构建记录的还是 bone-console-ui 的依赖
  'bone-console-ui@0.2',
  'alife-iot-components@1',
];
