import React from 'react';
import globalConfig from '@alife/iot-vapp-global-config';

/* 引入页面模型 */
import OneIdLogin from '@alife/iot-oneid-login';
import { LOGO_URL, BACKGROUND_IMAGE_URL } from '../constants';
import { getFixedPath } from './utils';
import styles from './index.scss';

export const CERTIFICATES_KEY = 'certificates';
export const CERTIFICATE_THEME_KEY = 'certificateTheme';

const { appKey, appName, pageList } = globalConfig;

export enum EAccountType {
  ACCOUNT = 'account',
  PHONE = 'phone',
}

export interface LoginPageCertificate {
  icon1: string;

  icon2: string;

  text: string;

  link: string;
}

export interface ILoginConfig {
  name: string;
  logo: string;
  backgroundImage: string;
  [CERTIFICATES_KEY]?: LoginPageCertificate[];
  [CERTIFICATE_THEME_KEY]?: 'light' | 'dark';
}
interface ILoginState {
  accountType: EAccountType;
  loginConfig?: ILoginConfig;
}

// scss module 在编辑会丢失
const titleStyle = {
  overflow: 'hidden' as 'hidden',
  textOverflow: 'ellipsis' as 'ellipsis',
  WebkitBoxOrient: 'vertical' as 'vertical',
  WebkitLineClamp: 2,
};
const params = {
  appKey,
  // 自行实现切换成验证码登录，loginTypes需传入['account'],
  loginTypes: ['phone'],
  // 登录成功的回调, 返回OAToken
  onSuccess: (data) => {
    window.location.href = getFixedPath({
      pathname: '/login/success',
      query: data,
      // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
      locationSearchKeys: ['redirect_url'],
    });
  },
  // 自定义底部模块
  footer: '',
  // 自定义样式
  style: {
    // 背景色默认是白色的，这里是覆盖默认背景色
    backgroundColor: '#ffffff',
  },
  // 自定义class
  className: 'loginBtn',
};
function CertificateIcon({ src }: { src: string }) {
  return (
    <div className={styles['certificate-icon-container']}>
      <img className={styles['certificate-icon']} src={src} />
    </div>
  );
}

export default class Login extends React.Component<{}, ILoginState> {
  state: ILoginState = {
    accountType: EAccountType.ACCOUNT,
  };

  componentDidMount() {
    this.loadLoginConfig();
  }

  async loadLoginConfig() {
    const loginPageUrl = (
      pageList.find(item => item.is_login_page) || {}
    ).cdn_url;

    const loginConfig: ILoginConfig = {
      logo: LOGO_URL,
      backgroundImage: BACKGROUND_IMAGE_URL,
      name: appName as string,
    };
    if (loginPageUrl) {
      const configed = await fetch(`${loginPageUrl}?t=${Date.now()}`).then(res => res.json());
      Object.keys(configed).forEach((key) => {
        if (configed[key]) {
          loginConfig[key] = configed[key];
        }
      });
    }
    this.setState({
      loginConfig,
    });
  }

  // onLoginFindPwd = () => {
  //   window.open(getFixedPath({
  //     pathname: FIND_PASSWORD_PATH,
  //     // 账号相关的 path 都要带上 redirect_url，避免实例和应用之间的登陆成功之后的链接混乱
  //     locationSearchKeys: ['token', 'redirect_url'],
  //   }));
  // };

  renderLoginContent() {
    const { accountType } = this.state;
    return (
      <div className={styles.loginBox}>
        <OneIdLogin {...params} />
      </div>
    );
  }

  render() {
    const { accountType, loginConfig } = this.state;
    if (!loginConfig) {
      return null;
    }

    const { [CERTIFICATES_KEY]: certificates, [CERTIFICATE_THEME_KEY]: certificateTheme } = loginConfig;

    return (
      <div className={styles.loginContainer}>
        <div className={styles.loginBanner} style={{ backgroundImage: `url(${loginConfig.backgroundImage})` }}>
          <h1 style={titleStyle}>{loginConfig.name || ' '}</h1>
        </div>
        {this.renderLoginContent()}
        {Array.isArray(certificates) && certificates.length > 0 && (
          <div className={styles.certificates}>

            <div className={styles.certificateItem}>
              {certificates.map(item => (
                <div className={styles.certificate}>
                  {item.icon1 && (<CertificateIcon src={item.icon1} />)}
                  {item.icon2 && (<CertificateIcon src={item.icon2} />)}
                  {item.link
                    ? (
                      <a
                        className={styles.certificateLink}
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className={styles.certificatesItemInfo}>{item.text}</div>
                      </a>
                    )
                    : (<div className={styles.certificatesItemInfo}>{item.text}</div>)}
                </div>
              ))}
            </div>
          </div>
          // </div>
        )}
      </div>
    );
  }
}
