import intl from '@alife/iot-intl';
import { currentLocale } from './locale';
import globalConfig from '@alife/iot-vapp-global-config';

const { env } = globalConfig;
declare global {
  interface Window {
    __INITIAL_STATE__: Record<string, any>;
    __ONEID_ENV__: Record<string, any>;
  }
}

// 设置全局环境变量
window.__ONEID_ENV__ = window.__ONEID_ENV__ || {};
window.__ONEID_ENV__.env = env;

intl.setLocale(currentLocale.split('-')[0]);
// 参数为美杜莎应用key
intl.register('iot-studio-webapp');

require('./account');
